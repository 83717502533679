const defaultState = () => ({
    list: [],
    total: null,
    pager: {},
});

const requests = {
    namespaced: true,
    name: 'sap',
    state: defaultState(),
    actions: {
        async getInfo({}, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('sapInfo', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: info = {} } = data;
            return Promise.resolve(info);
        },
        async getInfoNonsig({}, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('sapInfoNonsig', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: info = {} } = data;
            return Promise.resolve(info);
        },
    },
};

export default requests;
