import restApiRequest from '../apiRequestClients/restApiRequest';
import applyFilterParams from '../utils/filterParser';

const BASE_URL = '/requests';

export default apiUrl => {
    const apiRequest = restApiRequest(apiUrl);

    return {
        list(params = {}) {
            const sendParams = { url: BASE_URL, data: {} };

            Object.assign(sendParams.data, applyFilterParams(params, { role: 'role', request_type: 'request_type'}));
            return apiRequest.get(sendParams);
        },
        info(params = {}) {
            if (!params.request_id || typeof params.request_id !== 'number') {
                throw new Error({ error: `Expected requestId param to be Number, got ${typeof params.request_id}` });
            }

            const sendParams = { url: `${BASE_URL}/${params.request_id}`, data: {} };
            Object.assign(sendParams.data, applyFilterParams(params, { role: 'role', request_type: 'request_type'}));
            console.log('sendParams ', sendParams);

            return apiRequest.get(sendParams);
        },
        create(params = {}) {
            const sendParams = {
                url: BASE_URL,
                data: {
                    request_type: params.request_type,
                    jt_type_code: params.jt_type_code,
                    sda_approval_number: params.sda_approval_number,
                    sda_invoice_ref_number: params.sda_invoice_ref_number,
                    payment_type: params.payment_type,
                    nonsig_number: params.nonsig_number,
                    nonsig_name: params.nonsig_name,
                    nonsig_street_address: params.nonsig_street_address,
                    nonsig_city: params.nonsig_city,
                    nonsig_state: params.nonsig_state,
                    nonsig_zip: params.nonsig_zip,
                    common_owner_number: params.common_owner_number,
                    title: params.title,
                    payment_amount: params.payment_amount,
                    payment_memo: params.payment_memo,
                    additional_notes: params.additional_notes,
                    accounting_info: params.accounting_info,
                    material_numbers: params.material_number,
                    payment_currency: params.payment_currency,
                    is_taxable: params.is_taxable,
                },
            };

            return apiRequest.post(sendParams);
        },
        update(params = {}) {
            if (!params.request_id || typeof params.request_id !== 'number') {
                throw new Error({ error: `Expected request_id param to be Number, got ${typeof params.request_id}` });
            }

            const sendParams = {
                url: `${BASE_URL}/${params.request_id}`,
                data: {
                    request_type: params.request_type,
                    action: params.action,
                    reason: params.reason,
                    jt_type_code: params.jt_type_code,
                    sda_approval_number: params.sda_approval_number,
                    sda_invoice_ref_number: params.sda_invoice_ref_number,
                    payment_type: params.payment_type,
                    nonsig_number: params.nonsig_number,
                    nonsig_name: params.nonsig_name,
                    nonsig_street_address: params.nonsig_street_address,
                    nonsig_city: params.nonsig_city,
                    nonsig_state: params.nonsig_state,
                    nonsig_zip: params.nonsig_zip,
                    common_owner_number: params.common_owner_number,
                    title: params.title,
                    payment_amount: params.payment_amount,
                    payment_memo: params.payment_memo,
                    accounting_info: params.accounting_info,
                    material_numbers: params.material_number,
                    additional_notes: params.additional_notes,
                    sap_number: params.sap_number,
                    invoice_number: params.invoice_number,
                    jt_date: params.jt_date,
                    invoice_document: params.invoice_document,
                    payment_currency: params.payment_currency,
                    is_taxable: params.is_taxable,
                },
            };

            return apiRequest.post(sendParams);
        },
        changes(params = {}) {
            if (!params.request_id || typeof params.request_id !== 'number') {
                throw new Error({ error: `Expected request_id param to be Number, got ${typeof params.request_id}` });
            }

            const sendParams = {
                url: `${BASE_URL}/${params.request_id}`,
                data: {
                    form_state: params.form_state,
                    additional_notes: params.additional_notes,
                    history_id: params.history_id,
                    additional_notes_id: params.additional_notes_id,
                    invoice_document: params.invoice_document,
                    request_type: params.request_type,
                },
            };

            return apiRequest.patch(sendParams);
        },
        inProcess(params = {}) {
            if (!params.request_id || typeof params.request_id !== 'number') {
                throw new Error({ error: `Expected request_id param to be Number, got ${typeof params.request_id}` });
            }

            const sendParams = {
                url: `${BASE_URL}/${params.request_id}/in-process`,
                data: {
                    in_process: params.in_process,
                    request_type: params.request_type,
                },
            };

            return apiRequest.post(sendParams);
        },
    };
};
