export const roles = [
    {
        azureRole: 'CatInsights_Rebates_JT_Admin',
        innerRole: 'admin',
        priority: 0,
        type: 'admin',
    },
    {
        azureRole: 'GBS_Support_and_Control',
        innerRole: 'gbs_approver',
        priority: 1,
        type: 'rebate',
    },
    {
        azureRole: 'AHQ_Rebates_Approvers',
        innerRole: 'rebate_approver',
        priority: 2,
        type: 'rebate',
    },
    {
        azureRole: 'AR_Group',
        innerRole: 'ar_approver',
        priority: 1,
        typeRole: 'jt',
    },
    {
        azureRole: 'AHQ_Journal_Ticket_E_ApproverTwo',
        innerRole: 'jt_approver2',
        priority: 2,
        type: 'jt',
    },
    {
        azureRole: 'AHQ_Journal_Ticket_E_ApproverOne',
        innerRole: 'jt_approver1',
        priority: 3,
        type: 'jt',
    },
];

export const rolesList = [
    { name: 'Admin', value: 'admin' },
    { name: 'Rebate Approver', value: 'rebate_approver' },
    { name: 'GBS Approver', value: 'gbs_approver' },
    { name: 'Journal Ticket Approver 1', value: 'jt_approver1' },
    { name: 'Journal Ticket Approver 2', value: 'jt_approver2' },
    { name: 'AR Approver', value: 'ar_approver' },
    { name: 'Creator', value: 'creator' },
];
