import restApiRequest from '../apiRequestClients/restApiRequest';
//import applyPagerParams from '../utils/pagerParser';
//import applyFilterParams from '../utils/filterParser';
//import applySortingParams from '../utils/sortingParser';

const BASE_URL = '/sap';

export default apiUrl => {
    const apiRequest = restApiRequest(apiUrl);

    return {
        info(params = {}) {
            if (!params.id || typeof params.id !== 'number') {
                throw new Error({ error: `Expected rebate agreement number param to be Number, got ${typeof params.id}` });
            }

            const sendParams = { url: `${BASE_URL}/${params.id}`, data: {} };
            console.log('sendParams ', sendParams);

            return apiRequest.get(sendParams);
        },
        infoNonsig(params = {}) {
            if (!params.id || typeof params.id !== 'number') {
                throw new Error({ error: `Expected nonsig number param to be Number, got ${typeof params.id}` });
            }

            const sendParams = { url: `${BASE_URL}nonsig/${params.id}`, data: {} };
            console.log('sendParams ', sendParams);

            return apiRequest.get(sendParams);
        },
    };
};
